import React from 'react';
import './TechnologyStack.css';
import LowestBlock from '../NewHomePage/components/lowestBlock/lowestBlock';
import {ReactComponent as AwsLogo} from './assets/aws-logo.svg';
import {ReactComponent as ClickLogo} from './assets/Click House.svg';
import {ReactComponent as CloudflareLogo} from './assets/cloudflare-logo.svg';
import {ReactComponent as GoLogo} from './assets/Go-Logo_Blue.svg';
import {ReactComponent as NodeLogo} from './assets/Node.js_logo.svg';
import {ReactComponent as DocerLogo} from './assets/docker-logo.svg';
import {ReactComponent as FixLogo} from './assets/fix-logo.svg';
import {ReactComponent as KafkaLogo} from './assets/kafka-logo.svg';
import {ReactComponent as KuberLogo} from './assets/kuber-logo.svg';
import {ReactComponent as PostLogo} from './assets/postgresql.svg';
import {ReactComponent as ReactLogo} from './assets/react.svg';
import natsLogo from './assets/nats-horizontal-color.png';
import SEO from '../../components/SEO/SEO';
import imgTechnology from './assets/Proven technology stacks.png';

export default function TechnologyStack() {
  let arrCollapse = [
    {
      img: <AwsLogo />,
      alt: 'Aws',
      text: 'BBP solution is hosted on Amazon cloud infrastructure. It is scalable, reliable, and secure global computing infrastructure, that minimizes risks related to hardware',
    },
    {
      img: <CloudflareLogo />,
      alt: 'Cloud',
      text: 'BBP uses CloudFlare to protect from DDoS attacks and is responsible for load balancing. It helps to keep BBP setups secure, fast, and reliable',
    },
    {
      img: <KuberLogo />,
      alt: 'Kuber',
      text: 'Kubernetes, docker containers and AWS provide possibility of fast start for a new BBP setups, makes it easy to scale applications by adding containers as needed',
    },

    {
      img: <DocerLogo />,
      alt: 'Docer',
      text: 'Docker is a popular open-source platform that simplifies the process of building, shipping, and running applications using containerization technology',
    },
    {
      img: <PostLogo />,
      alt: 'Post',
      text: 'PostgreSQL is a free object-relational database management system. Available in implementations for many UNIX-like platforms, including AIX, various BSD systems',
    },
    {
      img: <ClickLogo />,
      alt: 'Click',
      text: 'ClickHouse is an open-source columnar analytical DBMS that allows you to run analytical queries in real time on structured big data, developed by Yandex',
    },

    {
      img: <KafkaLogo />,
      alt: 'Kafka',
      text: "Apache Kafka is a distributed streaming platform. It's primarily used for building real-time data pipelines and streaming applications",
    },
    {
      img: natsLogo,
      type: 'img',
      alt: 'Nats',
      text: 'NATS is a connective technology powering modern distributed systems, unifying Cloud, On-Premise, Edge, and IoT',
    },
    {
      img: <GoLogo />,
      alt: 'Go',
      text: 'Go is a compiled multi-threaded programming language developed internally by Google. Development of Go began in September 2007',
    },

    {
      img: <FixLogo />,
      alt: 'Fix',
      text: 'FIX, the industry-standard protocol trusted by leading buy and sell-side firms, crypto trading platforms, and regulators worldwide',
    },
    {
      img: <ReactLogo />,
      alt: 'React',
      text: 'React is the library for web and native user interfaces. Build user interfaces out of individual pieces called components written in JavaScript',
    },
    {
      img: <NodeLogo />,
      alt: 'Node',
      text: 'Node.js is a software platform based on the V8 engine that transforms JavaScript from a highly specialized language into a general-purpose language',
    },
  ];
  return (
    <>
      <SEO
        title="ScaleTrade Technology"
        description="Popular technological solutions. Proven technology stacks that guarantee trouble-free and high-quality operation of the provided product."
        name="ScaleTrade"
        type="article"
        link="technology-stack"
        img={imgTechnology}
      />

      <div className="TechnologyStack__Page container">
        <h1 className="title light-blue-gradient">Technology Stack</h1>

        <p className="main_text">
        Using modern, proven solutions allows us to create a highly advanced, technologically sophisticated, and reliable product. We use a broad stack of technologies for a multifaceted approach to development
        </p>

        <div className="TechnologyStack__collapse">
          {arrCollapse.map((i, index) => (
            <div className="collapseBlock" id={i.alt + index}>
              {i.type === 'img' ? <img src={i.img} alt={i.alt} className="collapseImg" /> : i.img}
              <div className="collapseText">
                <p className="main_text">{i.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <LowestBlock />
    </>
  );
}
