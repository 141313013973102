import React from "react";
import Accordion from '../../components/Accordion/accordion'
import "./vacancies.scss";
import SEO from "../../components/SEO/SEO";
import WallOfLove from "../About/components/WallOfLove/WallOfLove";
import LowestBlock from "../NewHomePage/components/lowestBlock/lowestBlock";
import FormCareer from "./Components/form/form";



export default function VacanciesPage() {

  const arrVacancies = [
    {
      title: "Middle Golang Developer",
      experience: "Minimum of 3 years",
      employment: "Full employment",
      schedule: "5/2",
      workingHours: "8 hours - Evening or night shifts",
      workFormat: "Remote work",
      responsibilities: ["Development of new and support of existing services", "Creating integrations between systems and APIs", "Active participation in the discussion and development of architecture"],
      requirements: ["Fluent spoken English is a must", "Excellent knowledge of the Golang language (3 years of experience)", "Experience with Kafka", "Experience with OpenTelemetry", "Experience with PostgreSQL, MySQL", "Understanding of the principles of REST, gRPC, Git, CI/CD",],
      conditions: ["Remote work", "A promising project with opportunities for professional development", "Probationary period - 2 months",]
    },
    {
      title: "Dealer/Administrator of the trading server",
      experience: "Minimum of 3 years",
      employment: "Full employment",
      schedule: "5/2",
      workFormat: "Remote work",
      responsibilities: ["Monitoring of MT4 systems and services", "Communication with developers regarding Manager API", "24/5 monitoring and identifying errors in the operation of applications and services", "Administration of group and individual settings for all account groups and instrument groups across company servers, including spreads, swaps, margins, commissions, trading hours, etc.", "Monitoring of client activity, identifying excessive load on trading systems and unauthorized access", "Monitoring of bridge operation."],
      requirements: ["Fluent in English", "MT4 administration experience (installing, configuring, maintaining, and troubleshooting MT4 systems)", "Understanding of MT4 object model (orders, trades, quotes, etc.)", "Server administration", "Forex/FinTech experience (minimum of 3 years)",],
      conditions: ["Remote work", "Probationary period - 1 month",]
    },
  ]

  return (
    <>
      <SEO
        title="Career in ScaleTrade"
        description="ScaleTrade technology provider which makes it possible to launch your own broker in the shortest time. Ready white label solution with the customization options to suit your business needs."
        name="ScaleTrade"
        type="article"
        link="/career"
      />

      <div className="career__Page">
        <section className="titlebox">
          <h1 className="title">Be (part of)</h1>
          <h1 className="title light-blue-gradient">the something new</h1>
        </section>
        <WallOfLove />
        <section>
          <div className="container">
            {
              arrVacancies.map((i, index) =>
                <Accordion title={i.title} key={index + i.title}>
                  <div className="career__info">
                    <div className="shortInfo">
                      <h4>General</h4>
                      <div className="main_text">
                        <p>Working experience:</p>
                        <p>{i.experience}</p>
                      </div>
                      <div className="main_text">
                        <p>Employment:</p>
                        <p>{i.employment}</p>
                      </div>
                      <div className="main_text">
                        <p>Schedule:</p>
                        <p>{i.schedule}</p>
                      </div>
                      {i.workingHours && <div className="main_text">
                        <p>Working hours:</p>
                        <p>{i.workingHours}</p>
                      </div>}
                      <div className="main_text">
                        <p>Work Format:</p>
                        <p>{i.workFormat}</p>
                      </div>
                    </div>
                    <div className="mainInfo">
                      <div>
                        <h4>Responsibilities</h4>
                        {
                          i.responsibilities.map((i, index) =>
                            <p key={"Responsibilities" + index} className="main_text"> - {i}</p>
                          )
                        }
                      </div>
                      <div>
                        <h4>Requirements</h4>
                        {
                          i.requirements.map((i, index) =>
                            <p key={"Requirements" + index} className="main_text">- {i}</p>
                          )
                        }
                      </div>
                      <div>
                        <h4>Conditions</h4>
                        {
                          i.conditions.map((i, index) =>
                            <p key={"Condition" + index} className="main_text"> - {i}</p>
                          )
                        }
                      </div>
                    </div>
                  </div>
                  <FormCareer />
                </Accordion>
              )
            }
          </div>
        </section>
      </div>
      <LowestBlock />
    </>
  )
}