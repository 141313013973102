import React from 'react';
import BlueBtn from '../../components/BlueBtn/BlueBtn';
import {ReactComponent as FeederSvg} from './Assets/svgFeeder.svg';
import './feeder.css';
import cboe from './Assets/Cboe.webp';
import coinbase from './Assets/coinbase.webp';
import finra from './Assets/finra.webp';
import {ReactComponent as Nasdaq} from './Assets/nasdaq.svg';
import nyse from './Assets/Nyse.webp';
import {ReactComponent as Otc} from './Assets/otc.svg';
import LowestBlock from '../NewHomePage/components/lowestBlock/lowestBlock';
import SEO from '../../components/SEO/SEO';
import imgFeeder from './Assets/Professional quote supplier.png';
import useWidth from '../../actions/getWidth';
import NewForm from '../../components/Banner/components/newForm/newForm';

export default function FiderPage() {
  let width = useWidth();
  let theme = localStorage.getItem('Theme')

  let Fider;
  if (width >= 768) {
    if (theme == `"dark"`) {
    Fider = require('./Assets/feeder.webp');
    }else{
    Fider = require('./Assets/feeder light.png');
    }
  }
  const arrFider = [
    {img: cboe, alt: 'cboe', type: 'img'},
    {img: coinbase, alt: 'coinbase', type: 'img'},
    {img: finra, alt: 'finra', type: 'img'},
    {img: <Nasdaq />, alt: 'nasdaq', type: 'svg'},
    {img: nyse, alt: 'nyse', type: 'img'},
    {img: <Otc />, alt: 'otc', type: 'svg'},
  ];

  return (
    <>
      <SEO
        title="ScaleTrade Feeder"
        description="Trading data from the most accurate and verified sources. Get access to real-time prices, historical data and news on popular currencies with the help of our ST feeder."
        name="ScaleTrade"
        type="article"
        link="/feeder"
        img={imgFeeder}
      />
      <div className="feeder__Page">
        <h1 className="container title light-blue-gradient"> ScaleTrade Feeder</h1>
        <p className="container crm__Page-text main_text">
        In the fast-paced world of financial trading, the information that is accurate and timely has a  critical meaning. One of the most important components of this information flow is the quote feed. Scaletrade provides a service that delivers feeds of quotes directly to your platform in real time, allowing traders to track market movements and execute trades efficiently. The feed contains volume, bid and ask prices, and other relevant market data.
        </p>
        <div className="container two_sides row">
          <div className="feeder_img_box col-lg-6">
            <img src={Fider} alt={'ScaleTrade Feeder image'} className="feeder_img_type" />
          </div>
          <div className="col-lg-6">
            <ul className="crm__Page-list main_text">
              <li>Integration with any trading platforms</li>
              <li>
                Access to real-time quotes without the need for additional software or subscriptions
              </li>
              <li>Seamless transfer of quotes</li>
              <li>Instant access to up-to-date market data</li>
            </ul>
            <BlueBtn btn_text="Contact Us" click={true} type="subForm"/>
          </div>
        </div>
        <div className="container center_box sources">
          <h2 className="sub_title light-blue-gradient">Our sources of quotes</h2>
          <p className="main_text">
            Rest assured you are getting your data from the most accurate and verified sources.
          </p>
          <div className="row">
            {arrFider.map((i, index) => (
              <div className="col-md-2 col-6" key={index}>
                <div className="sources_box">
                  {i.img && i.type === 'img' && (
                    <img
                      src={i.img}
                      alt={i.alt}
                      className={`sources_img ${i.alt === 'nyse' && 'nyse'}`}
                    />
                  )}
                  {i.img && i.type === 'svg' && i.img}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="container center_box">
          <h2 className="sub_title light-blue-gradient">Give your customers a wide choice</h2>
          <p className="main_text">
            Get access to real-time prices, historical data and news on popular currencies.
          </p>
        </div>
        <FeederSvg className="feederSvg" />
      </div>
      <LowestBlock />
    </>
  );
}
