import { createStore } from 'redux';

export default function Store() {
  const checkLang = localStorage.getItem('languageLocal');
  let lang = '';
  if (checkLang == null) {
    lang = 'en';
    localStorage.setItem('languageLocal', 'en');
  } else {
    lang = localStorage.getItem('languageLocal');
  }
  const store = createStore(
    (
      state = {
        selectedLang: lang,
        collapsedLang: 'hiden',
        langTokens: {},
        showBanner: false,
        showThx: false,
        selectForm: 'None',
        formType: "None",
        changeTheme: JSON.parse(localStorage.getItem('Theme')) || 'light',
      },
      action,
    ) => {
      const nextState = { ...state };
      switch (action.type) {
        case 'TOKENS':
          nextState.langTokens = action.payload;
          break;
        case 'CHANGELANGUAGE':
          nextState.selectedLang = action.payload;
          localStorage.setItem('languageLocal', action.payload);
          break;
        case 'CHANGELCOCALCOLAPSESESTATE':
          nextState.collapsedLang = action.payload;
          break;
        case 'SHOWBANNER':
          nextState.showBanner = action.payload;
          break;
        case 'SHOWTHX':
          nextState.showThx = action.payload;
          break;
        case 'SELECTFORM':
          nextState.selectForm = action.payload;
          break;
        case 'FORMTYPE':
          nextState.formType = action.payload;
          break;
        case 'CHANGETHEME':
          nextState.changeTheme = action.payload;
          break;
        default:
          break;
      }
      return nextState;
    },
  );
  return store;
}
