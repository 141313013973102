import React from 'react';
import './showThx.scss';
import {useDispatch} from 'react-redux';

export default function ShowThx() {
  const dispatch = useDispatch();

  return (
    <div
      className="ShowThx"
      onClick={(e) =>
        dispatch({type: 'SHOWTHX', payload: false}) &&
        dispatch({type: 'SHOWBANNER', payload: false}) && 
        dispatch({ type: 'SELECTFORM', payload: "None" }) &&
        dispatch({ type: 'FORMTYPE', payload: 'None' })
      }
    >
      <div className="thxMessage">
        <h2 className="sub-Title main_text">
          Thank you for your request, our manager will contact you shortly
        </h2>
      </div>
    </div>
  );
}
