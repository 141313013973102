import React from 'react';
import './subscription.scss';
import {useDispatch} from 'react-redux';

export default function Subscription() {
  const dispatch = useDispatch();
  return (
    <div className="container">
      <div className="subscription">
        <h1 className="title light-blue-gradient">Subscription Product</h1>
        <div className="select">
          <div className="plan">
            <h2 className="light-gradient">ST CRM</h2>

            <div className="priceBox">
              <div className="monthPrice">
                <h2 className="light-blue-gradient">€1,800</h2>
              </div>
              <p className="yearPrice">
                /month <br />
                €0/Set up fee
              </p>
            </div>
            <div className="includes_box">
              <h3 className="light-blue-gradient includes">Package includes:</h3>

              <ul className="includes_list">
                <li className="main_text">Native VoIP</li>
                <li className="main_text">Custom workflow</li>
                <li className="main_text">Analitical tools</li>
              </ul>
            </div>

            <div>
              <a
                className="btn_subscribe"
                onClick={() => {dispatch({type: 'SHOWBANNER', payload: true})
                dispatch({ type: 'FORMTYPE', payload: 'subForm' })
              }}
              >
                Talk to sales
              </a>
            </div>
            <p className="description">
              10 manager’s accounts are included <br /> Every additional "10 manager’s accounts"
              adds €300/monthly. After reach "50+ managers", the fee does not increase further.
            </p>
          </div>
          <div className="plan">
            <h2 className="light-gradient">ST Trader</h2>

            <div className="priceBox">
              <div className="monthPrice">
                <h2 className="light-blue-gradient">€1,200</h2>
              </div>
              <p className="yearPrice">
                /month <br />
                €0/Set up fee
              </p>
            </div>
            <div className="includes_box">
              <h3 className="light-blue-gradient includes">Package includes:</h3>
              <ul className="includes_list">
                <li className="main_text">Web terminal</li>
                <li className="main_text">Desktop terminal</li>
                <li className="main_text">Mobile apps terminal</li>
                <li className="main_text">Analytical engine</li>
                <li className="main_text">Multi-charts</li>
              </ul>
            </div>
            <div>
              <a
                className="btn_subscribe"
                onClick={() => {dispatch({type: 'SHOWBANNER', payload: true})
                dispatch({ type: 'FORMTYPE', payload: 'subForm' })
              }}
              >
                Talk to sales
              </a>
            </div>
            <p className="description">
              1,000 active trading accounts are included <br /> Every additional "1,000 active
              accounts" adds €300/monthly. After reach "10,000+ accounts", the fee does not increase
              further.
            </p>
          </div>
          <div className="plan">
            <h2 className="light-gradient">Price feed</h2>
            <div className="priceBox">
              <div className="monthPrice">
                <h2 className="light-blue-gradient">€700</h2>
              </div>
              <p className="yearPrice">
                Monthly fee for one Tier <br /> €0/Set up fee
              </p>
            </div>
            <div className="includes_box">
              <h3 className="light-blue-gradient includes">Package includes:</h3>

              <ul className="includes_list">
                <li className="main_text">6 Asset Classes</li>
                <li className="main_text">Many Sources</li>
                <li className="main_text">Low Latency</li>
                <li className="main_text">High Accuracy</li>
              </ul>
            </div>
            <a
              className="btn_subscribe"
              onClick={() => {dispatch({type: 'SHOWBANNER', payload: true})
                dispatch({ type: 'FORMTYPE', payload: 'subForm' })
              }}
            >
              Talk to sales
            </a>
            <p className="description">
              The price is per 1 Tier <br /> Tier 1 - FX Crypto, Indices, Commodities (130 smb){' '}
              <br /> Tier 2 – US shares (450 smb) <br /> Tier 3 – Europe shares (205 smb)
            </p>
          </div>
        </div>
      </div>
      <div className="subscription">
        <h2 className="title light-blue-gradient">Subscription Module</h2>
        <div className="select_modules">
          <div className="plan">
            <h2 className="light-gradient">Copy trading</h2>

            <div className="priceBox">
              <div className="monthPrice">
                <h2 className="light-blue-gradient">€1,000</h2>
              </div>
              <p className="yearPrice">
                /month <br />
                €0/Set up fee
              </p>
            </div>
            <div className="includes_box">
              <h3 className="light-blue-gradient includes">Package includes:</h3>
              <ul className="includes_list">
                <li className="main_text">Rating</li>
                <li className="main_text">Master and investor interface</li>
                <li className="main_text">CT management</li>
              </ul>
            </div>
            <div>
              <a
                className="btn_subscribe"
                onClick={() => {dispatch({type: 'SHOWBANNER', payload: true})
                dispatch({ type: 'FORMTYPE', payload: 'subForm' })
              }}
              >
                Talk to sales
              </a>
            </div>
            <p className="description">Supplied as an addition to the ST Trader+ ST CRM.</p>
          </div>
          <div className="plan">
            <h2 className="light-gradient">Analytical set</h2>

            <div className="priceBox">
              <div className="monthPrice">
                <h2 className="light-blue-gradient">€500</h2>
              </div>
              <p className="yearPrice">
                /month <br />
                €0/Set up fee
              </p>
            </div>
            <div className="includes_box">
              <h3 className="light-blue-gradient includes">Package includes:</h3>

              <ul className="includes_list">
                <li className="main_text">News feed</li>
                <li className="main_text">Economic calendar investor interface</li>
                <li className="main_text">Trading ideas</li>
              </ul>
            </div>

            <div>
              <a
                className="btn_subscribe"
                onClick={() => {dispatch({type: 'SHOWBANNER', payload: true})
                dispatch({ type: 'FORMTYPE', payload: 'subForm' })
              }}
              >
                Talk to sales
              </a>
            </div>
            <p className="description">Supplied as an addition to ST Trader.</p>
          </div>
        </div>
      </div>
    </div>
  );
}
