import { useState, useRef, useEffect } from "react";
import './accordion.scss';

const Accordion = ({ children, title }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [contentHeight, setContentHeight] = useState(0);
    const contentRef = useRef();

    useEffect(() => {
        if (contentRef.current) {
            setContentHeight(contentRef.current.scrollHeight);
        }
    }, [isOpen]);

    return (
        <div className={`ios__list-item accordion__row${isOpen ? ' open' : ''}`}>
            <div className="accordion__btn" onClick={() => setIsOpen(!isOpen)}>
                <h2 className="sub_title light-blue-gradient">
                    {title}
                </h2>
                <p className="open_cross"></p>
            </div>
            <div
                className="accordion__collapse"
                ref={contentRef}
                style={isOpen ? { height: `${contentHeight}px` } : { height: 0 }}
            >
                <div className="dropdown-item dropdown-item-centered">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Accordion;
