import React from 'react';
import TwoSides from '../../components/twoSidesBlock/TwoSides';
import CrmMain from './components/crmMain/crmMain';
import CrmTitle from './components/crmTitle/crmTitle';

import LowestBlock from '../NewHomePage/components/lowestBlock/lowestBlock';
import SEO from '../../components/SEO/SEO';
import imgCRM from './assets/Advanced Crm For Brokers.jpg';
import useWidth from '../../actions/getWidth';
import { ReactComponent as PayPal} from './assets/integrationIcon/PayPal.svg';
import bank from './assets/integrationIcon/bank-wire.png';
import { ReactComponent as Shufti} from './assets/integrationIcon/shufti-pro.svg';
import jcc from './assets/integrationIcon/jcc-gateway.webp';
import { ReactComponent as Sumsub} from './assets/integrationIcon/sumsub.svg';
import { ReactComponent as Aircall} from './assets/integrationIcon/aircall.svg';
import { ReactComponent as Zadarma} from './assets/integrationIcon/zadarma.svg';
import bridger from './assets/integrationIcon/bridger-pay.webp';
import { ReactComponent as Astro} from './assets/integrationIcon/astro-pay.svg';
import regtek from './assets/integrationIcon/regtek.webp';
import { ReactComponent as Identomat} from './assets/integrationIcon/identomat.svg';
import { ReactComponent as Commpeak} from './assets/integrationIcon/commpeak.svg';

import './crmPage.scss';

export default function CrmPage() {
  let width = useWidth();
  let Crm, Crm2;

  let theme = localStorage.getItem('Theme')

  if (width >= 768) {
    if (theme == `"dark"`) {
      Crm = require('./assets/crm-page-2.webp');
      Crm2 = require('./assets/crm-page-3.webp');
    }else{
      Crm = require('./assets/crm-page-2 light.png');
      Crm2 = require('./assets/crm-page-3 light.png');
    }
  }
  const arrCrm = [
    {
      title: 'Administrative Tools and Сonfiguration of  the role system',
      main_text: '',
      list: [
        {
          item: 'Built-in and managed system of roles and accesses allow you to create specific accounts in CRM.',
        },
        {
          item: 'Restrictions of managers access to read, edit, and delete information. Setting up visibility scope.',
        },
        {item: 'Tables with a specific set of working tools and data visibility zones.'},
        {item: "Protection of information from quick copying in the client's card."},
      ],
      img: Crm,
      side: 'left',
    },
    {
      title: 'Linking  payment systems',
      main_text: '',
      list: [
        {
          item: 'Built-in payment module allows you to receive and send customer transactions.',
        },
        {item: 'Dozens of pre-installed connectors to payment systems.'},
        {item: 'Ability to integrate a new payment system.'},
        {item: 'Automatic currency conversion to the account currency.'},
      ],
      img: Crm2,
      side: 'right',
      last: true,
    },
  ];
  const arrIntegration = [
    {img: <PayPal />},
    {img: bank, alt: "bank-wire"},
    {img: <Shufti />},
    {img: jcc, alt: "jcc"},
    {img: <Sumsub />},
    {img: <Aircall />},
    {img: <Zadarma />},
    {img: bridger, alt: "bridger"},
    {img: <Astro />},
    {img: regtek, alt: "regtek"},
    {img: <Identomat />},
    {img: <Commpeak />},
  ]
  return (
    <>
      <SEO
        title="ScaleTrade CRM"
        description="Multifunctional and convenient CRM for trading broker. Manage sales, trading accounts, payments and business processes smartly. Сlient's information in one click."
        name="ScaleTrade"
        type="article"
        link="/crm"
        img={imgCRM}
      />
      <CrmTitle />
      <CrmMain />
      {arrCrm.map((i, index) => (
        <TwoSides
          title={i.title}
          main_text={i.main_text}
          list_arr={i.list}
          img={i.img}
          side={i.side}
          link={i.link}
          last={i.last}
          key={index}
        />
      ))}
      <section className='container integration'>
        <h2 className='crm_mainPage-subTitle sub_title light-blue-gradient'>100+ integrations</h2>
        <div className='integration-cardBox'>
          {arrIntegration.map((i, index) => 
            <div className='integration-card' key={index + i.alt}>
              {i.alt ? <img src={i.img} alt={i.alt} /> : i.img}
            </div>
          )}
        </div>
      </section>
      <LowestBlock />
    </>
  );
}
