import React from 'react';
import {Helmet} from 'react-helmet-async';
import seoImg from "../../Pages/HomePage/assets/Trading margin platform.png"

export default function SEO({title, description, name, type, link, keywords, img}) {

  if (!img) {
    img = seoImg
  }

  return (
    <Helmet>
      <title>ScaleTrade - Technology provider for Brokers  | {title}</title>
      <meta name="description" content={description} />
      <meta property="og:site_name" content="ScaleTrade" />
      <meta property="og:type" content="website" />
      <meta name="og:image" content={img} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:title" content={"ScaleTrade - Technology provider for Brokers  | " + title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content="https://scaletrade.com/" />
      <meta property="twitter:site" content="ScaleTrade" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={img} />
      <meta name="twitter:title" content={"ScaleTrade - Technology provider for Brokers  | " + title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:url" content="https://scaletrade.com/" />
      <meta name="keywords" content={keywords} />
      <link rel="canonical" href="https://scaletrade.com/" />

    </Helmet>
  );
}
