import React, {useState} from 'react';
import './Header.scss';
import MiddleContent from './components/MiddleContent.jsx';
import {ReactComponent as WhiteLogo} from '../../assets/whitelogo.svg';
import {ReactComponent as BlackLogo} from '../../assets/blacklogo.svg';
import {ReactComponent as Moon} from './assets/moon.svg';
import {ReactComponent as Sun} from './assets/sun.svg';
import {useSelector} from 'react-redux';
import useWidthHook from '../Hooks/WidthState.jsx';
import {useDispatch} from 'react-redux';

export default function NavHead() {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.selectedLang);
  let Width = useWidthHook();

  const [theme, setTheme] = useState(() => {
    const mode = JSON.parse(localStorage.getItem('Theme'));
    return mode || 'light';
  });

  const switchTheme = () => {
    setTheme((cur) => {
      const newTheme = cur === 'light' ? 'dark' : 'light';
      localStorage.setItem('Theme', JSON.stringify(newTheme));
      dispatch({type: 'CHANGETHEME', payload: newTheme});
      return newTheme;
    });
  };

  return (
    <nav className={`fixed-top alfa_header`}>
      <div className="header_content">
        <div className="logo">
          <a className="navbar-brand" href="/" style={{width: '50px'}}>
            {theme === 'light' ? (
              <BlackLogo style={{width: '200px', height: '28px'}} />
            ) : (
              <WhiteLogo style={{width: '200px', height: '28px'}} />
            )}
          </a>
        </div>
        {Width < 650 && (
          <div className="toggle-box">
            <input
              id="switch"
              className="toggle-switch"
              type="checkbox"
              onChange={() => {
                switchTheme();
              }}
              checked={theme === 'dark' ? true : false}
            />
            <label htmlFor="switch">
              <Sun className="toggle-sun" />
              <Moon className="toggle-moon" />
            </label>
          </div>
        )}
        <MiddleContent />
        <div className="header_right_content">
          {Width > 650 ? (
            <>
              <div className="toggle-box">
                <input
                  id="switch"
                  className="toggle-switch"
                  type="checkbox"
                  onChange={() => {
                    switchTheme();
                  }}
                  checked={theme === 'dark' ? true : false}
                />
                <label htmlFor="switch">
                  <Sun className="toggle-sun" />
                  <Moon className="toggle-moon" />
                </label>
              </div>
              <a>
                <button
                  type="button"
                  className="alfa-presentation"
                  onClick={() => {
                    dispatch({type: 'SHOWBANNER', payload: true});
                    dispatch({type: 'FORMTYPE', payload: 'subForm'});
                  }}
                >
                  <div className="flex-column">
                    <span className="alfa-span-btn alfa-span-btn-color">Turn-key broker</span>
                    <small className="alfa-small"></small>
                  </div>
                </button>
              </a>
              <a>
                <button
                  type="button"
                  className="alfa-demo-access"
                  onClick={() => {
                    dispatch({type: 'SHOWBANNER', payload: true});
                    dispatch({type: 'FORMTYPE', payload: 'subForm'});
                  }}
                >
                  <div className="flex-column">
                    <span className="alfa-span-btn">Contact Us </span>
                  </div>
                </button>
              </a>
            </>
          ) : (
            <div> </div>
          )}
        </div>
      </div>
    </nav>
  );
}
