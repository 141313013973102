import React from 'react';
import HomeTitle from './components/titleBlock/homeTitle.jsx';
import './newHomePage.css';
import TwoSides from '../../components/twoSidesBlock/TwoSides.jsx';
import LowestBlock from './components/lowestBlock/lowestBlock.jsx';
import {ReactComponent as FastImg} from './assets/fast.svg';
import {ReactComponent as ReadyImg} from './assets/ready-to-go.svg';
import {ReactComponent as SecureImg} from './assets/secure.svg';
import {ReactComponent as StableImg} from './assets/stable.svg';
import {ReactComponent as BackImage} from './assets/blurTitle.svg';
import {ReactComponent as DataImg} from './assets/data.svg';
import {ReactComponent as MailImg} from './assets/mail.svg';
import {ReactComponent as TimeImg} from './assets/time.svg';
import {ReactComponent as UptimeImg} from './assets/uptime.svg';
import SEO from '../../components/SEO/SEO.jsx';
import seoimg from './assets/Forex software provider.png';
import useWidth from '../../actions/getWidth.js';

export default function NewHomePage() {
  let width = useWidth();
  let terminal_img, crm_img, feeder_img;
  let theme = localStorage.getItem('Theme')

  if (width >= 768) {
    if (theme == `"dark"`) {
      terminal_img = require('./assets/ST-Platform.webp');
      crm_img = require('./assets/ST-CRM.webp');
      feeder_img = require('./assets/ST-Feeder.webp');
    }else{
      terminal_img = require('./assets/ST-Platform light.png');
      crm_img = require('./assets/ST-CRM light.png');
      feeder_img = require('./assets/ST-Feeder light.png');
    }
  }

  const arrTest = [
    {
      title: 'Trading platform',
      main_text:
        "The best solution for brokers' margin trading. Increase the trading turnover thanks to the ScaleTrade platform. Use the trading platform ScaleTrade. The platform is popular with both beginners and experienced traders. Due to a simplified and intuitive interface, users will be able to open trades within five minutes of getting to know the platform.",
      list: [
        {item: 'Web version of the platform'},
        {item: 'Mobile applications for Android and iOS'},
        {item: 'Desktop for Windows/Linux and Mac'},
      ],
      img: terminal_img,
      alt: 'Trading platform',
      btn_text: 'Trading platform',
      side: 'left',
      link: '/platform',
    },
    {
      title: 'CRM System',
      main_text: `Manage your business on your own rules with advanced CRM modules. Our CRM is fully integrated with the trading platform management control system for successful leading of your business. Manage your process by pressing a few keys.`,
      list: [
        {item: 'Full integration with the platform'},
        {item: 'Role-playing control system'},
        {item: 'Comfortable and simple interface'},
      ],
      img: crm_img,
      alt: 'CRM System',
      btn_text: 'CRM System',
      side: 'right',
      link: '/crm',
    },
    {
      title: 'Quotes Feeder',
      main_text:
        'Subscribe to our feed of quotes for the most popular and traded assets. Provide your clients with quality quotes for US stocks, cryptocurrencies, popular European stocks, indices and commodities.',
      list: [
        {item: 'Easy-to-use API'},
        {item: 'Stream real-time prices'},
        {item: 'Get historical data'},
      ],
      img: feeder_img,
      alt: 'Quotes Feeder',
      btn_text: 'ScaleTrade Feeder',
      side: 'left',
      link: '/feeder',
    },
  ];

  const arrWhy = [
    {
      img: <SecureImg />,
      alt: 'Secure',
      title: 'Secure',
      main_text: 'We safeguard our products from both internal and external threats',
    },
    {
      img: <FastImg />,
      alt: 'Fast',
      title: 'Fast',
      main_text: 'Empower your trading platform with ultra-fast execution',
    },
    {
      img: <StableImg />,
      alt: 'Stable',
      title: 'Stable',
      main_text: 'ScaleTrade Brokerage Solution uptime is 99%',
    },
    {
      img: <ReadyImg />,
      alt: 'Ready-to-go',
      title: 'Ready-to-go',
      main_text: 'High level our products readiness for quick launch',
    },
  ];
  return (
    <>
      <SEO
        title="ScaleTrade"
        description="Technology provider for brokers. All in one solution for quick broker's launch. Use a ready-made trading solution for your business Webtrading platform, CRM and Trading engine."
        name="ScaleTrade"
        type="article"
        img={seoimg}
      />
      <BackImage className="background_Svg" />
      <HomeTitle />
      <div className="container">
        <div className="cardContainer">
          <div className="cardAdvantages">
            <h2>
              1 Trillion+ <DataImg />
            </h2>
            <p>Rows of data</p>
          </div>
          <div className="cardAdvantages">
            <h2>
              20 ms <UptimeImg />
            </h2>
            <p>Execution order</p>
          </div>
          <div className="cardAdvantages">
            <h2>
              99.99% <TimeImg />
            </h2>
            <p>Uptime</p>
          </div>
          <div className="cardAdvantages">
            <h2>
              70 Million <MailImg />
            </h2>
            <p>Messages/sec</p>
          </div>
        </div>
      </div>
      {arrTest.map((i, index) => (
        <TwoSides
          title={i.title}
          main_text={i.main_text}
          list_arr={i.list}
          img={i.img}
          alt={i.alt}
          text_btn={i.btn_text}
          side={i.side}
          link={i.link}
          last={i.last}
          key={index}
        />
      ))}
      <div className="container">
        <div className="whyST">
          <h2 className="sub_title light-blue-gradient">Why ScaleTrade</h2>
          <div className="whyST_cardBox">
            {arrWhy.map((i, index) => (
              <div className="whyST_card" key={index}>
                {i.img}
                <h3>{i.title}</h3>
                <p className="why_text">{i.main_text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <LowestBlock />
    </>
  );
}
