import React, {useEffect, useState} from 'react';
import './homeTitle.css';
import BlueBtn from '../../../../components/BlueBtn/BlueBtn';
import {ReactComponent as Arrow} from '../../../../assets/arrow.svg';
import {ReactComponent as BackImage} from '../../assets/title.svg';
import useWidth from '../../../../actions/getWidth';

export default function HomeTitle() {
  let width = useWidth();
  let stAward;
  if (width >= 768) {
    stAward = require('../../assets/IMG_7739.webp');
  }
  return (
    <div className="title_block mainpage-ti-solution container">
      <BackImage className="backgroundTitle_Svg" />
      <div className="TwoSideTitle">
        <div className="text_box">
          <div className="overTitle_Block">
            <a href="https://blog.scaletrade.com/">What’s new</a>
          </div>
          <div className="platform_centered">
            <h1 className="main_title two_stroke">
              <span className="light-blue-gradient">ScaleTrade</span> <br />
              Best technology <br /> provider for{' '}
              <span className="light-blue-gradient">Brokers</span>
            </h1>
          </div>
          <div className="platform_centered">
            <p className="main_text indent_title_text">
              Use a ready-made trading solution for your business Webtrading platform, CRM and
              Trading engine. Everything you need - in one place. The best solution for brokers'
              margin trading. Increase the trading turnover thanks to the ScaleTrade platform. Order
              a full package of services, launch your broker from scratch with ScaleTrade. We have
              collected the best all together for your convenience
            </p>
          </div>
          <div className="platform_centered title_btnBox">
            <BlueBtn btn_text="Trading Platform" link="/platform" />
            <BlueBtn btn_text="CRM System" link="/crm" />
            {/* <BlueBtn btn_text="Modules" link="/copy-trading" /> */}
            <BlueBtn btn_text="Book a demo" click={true} type="subForm" />
          </div>
        </div>
        {width >= 768 && (
          <div className="titleImgBlock">
            <img src={stAward} alt="ScaleTrade Award" className="stAward" />
          </div>
        )}
      </div>
    </div>
  );
}
