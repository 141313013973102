import React from 'react';
import './Footer.css';
import SocialLink from './components/SocialLink.jsx';
import {ReactComponent as Logo} from '../../assets/whitelogo.svg';
import {ReactComponent as BlackLogo} from '../../assets/blacklogo.svg';
import {ReactComponent as LinkedInIco} from './assets/linkedin-color-off.svg';
import {ReactComponent as TelegrammIco} from './assets/telegramm-color-off.svg';
import {useSelector} from 'react-redux';

export default function Footer() {
  const changeTheme = useSelector((state) => state.changeTheme);

  let arrSocialLink = [
    {
      link: 'https://ee.linkedin.com/company/scaletrade',
      image: <LinkedInIco />,
      classlink: 'alfa-footer-socials__icon--linkedin',
    },
    // {
    //   link: 'https://t.me/ScaleTradeBot',
    //   image: <TelegrammIco />,
    //   classlink: 'alfa-footer-socials__icon--telegramm',
    // },
  ];
  const arrProducts = [
    {href: '/platform', item: 'ST Platform'},
    {href: '/crm', item: 'ST CRM'},
    {href: '/feeder', item: 'ST Feeder'},
  ];
  const arrWidgets = [
    {href: '/copy-trading', item: 'Copy Trading'},
    {href: '/prop-trading', item: 'Prop Trading'},
    {href: '#', item: 'Trading robots'},
  ];
  const arrCompany = [
    {href: '/about', item: 'About'},
    {href: 'https://blog.scaletrade.com/', item: 'Blog'},
    {href: '/technology-stack', item: 'Technology Stack'},
    {href: '/career', item: 'Сareer'},
  ];
  const arrSupport = [
    {href: '#', item: 'Api'},
    {href: '#', item: 'Support'},
    {href: 'https://status.scaletrade.io/', item: 'Status Page'},
  ];
  const arrLinks = [
    {title: 'Products', arr: arrProducts},
    {title: 'Modules', arr: arrWidgets},
    {title: 'Support', arr: arrSupport},
    {title: 'Company', arr: arrCompany},
  ];
  return (
    <>
      <hr className="alfa-footer-separator" />
      <footer className="alfa-footer">
        <div className="alfa-footer_row">
          <div className="alfa-footer-row-flex">
            <div className="alfa-footer_logo-socials">
              <a className="alfa-footer-logo" href="/">
                {changeTheme === 'light' ? (
                  <BlackLogo style={{width: '200px', height: '28px'}} />
                ) : (
                  <Logo style={{width: '200px', height: '28px'}} />
                )}
              </a>
              <a
                className="main_text centered-footer adresLink"
                target="_blank"
                href="https://maps.app.goo.gl/ZJKhmBLaMfq46MRX9"
              >
                Vesivärava tn 50-301, Tallinn, Estonia, 10152
              </a>
            </div>
            <div className="dropup">
              <div className="alfa-footer-links">
                <div className="alfa-footer-links__row-group">
                  <div className="alfa-footer-links-column">
                    {arrLinks.map((i, index) => (
                      <div key={index}>
                        <ul className="alfa-footer-links-column__list">
                          <p>{i.title}</p>
                          {i.arr.map((i, index) => (
                            <li
                              className={`${i.href === '#' && 'footer_text-disabled'}`}
                              key={index}
                            >
                              <a
                                className="alfa-footer-links-column__link"
                                href={i.href !== '#' && i.href}
                              >
                                {i.item}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <ul className="alfa-footer-socials">
              {arrSocialLink.map((i, index) => (
                <SocialLink link={i.link} image={i.image} classlink={i.classlink} key={index} />
              ))}
            </ul>
            <div className="alfa-footer-info centered-footer-links">
              <a
                href="mailto:sales@scaletrade.com"
                className="alfa-footer-info-links alfa-footer-info-links-mail"
              >
                <p className="main_text">sales@scaletrade.com</p>
              </a>
              <a href="tel:+3726148035" className="alfa-footer-info-links">
                <p className="main_text">+372 6148035</p>
              </a>
            </div>
          </div>
        </div>
        <div className="alfa-footer__markets-copyright">
          <div className="alfa-footer-markets">
            <div className="alfa-footer-markets__row-group">
              <a href="/privacy-policy" className="alfa-footer-links-column__link">
                Privacy policy
              </a>
            </div>
          </div>
          <div className="alfa-footer-copyright">
            <span className="alfa-footer-copyright__date">© 2022-2025 ScaleTrade</span>
          </div>
        </div>
      </footer>
    </>
  );
}
