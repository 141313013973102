import React from "react";
import "../../vacancies.scss";
import axios from "axios";
import { useForm } from 'react-hook-form';

import { useDispatch, useSelector } from "react-redux";
import ShowThx from "../../../../components/Banner/components/showThx/showThx";



export default function FormCareer() {

  const showThx = useSelector((state) => state.showThx);

  const pasGenerator = () => {
    const lowercaseChars = "abcdefghijklmnopqrstuvwxyz";
    const uppercaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const numbers = "0123456789";
    const symbols = "@$!%*#?&^_-";
    let length = 12

    let allChars = lowercaseChars;
    allChars += numbers;
    allChars += symbols;
    allChars += uppercaseChars;

    let generatedPassword = "";
    generatedPassword += lowercaseChars[Math.floor(Math.random() * lowercaseChars.length)];
    generatedPassword += numbers[Math.floor(Math.random() * numbers.length)];

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * allChars.length);
      generatedPassword += allChars[randomIndex];
    }

    return generatedPassword
  }

  const formSubmit = (data) => {
    console.log(data);

    axios({
      method: 'post',
      url: 'https://p1.x8y872i.com/endpoint/dev/mt/customer/registration',
      data: {
        email: data.email,
        password: pasGenerator(),
        brand: 'scale',
        prefix: 'sct',
        phone: data.phone,
        name: data.name,
        country: "",
        subfields: {
          type: "Career",
          product: "",
          messager: data.telegram,
          link: data.link,
          note: data.note
        }
      },
    })
      .then(() => {
        dispatch({ type: 'SHOWTHX', payload: true });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    console.log(data);
    formSubmit(data);
  };
  console.log(errors);

  return (
    <>
     <form className="career__Form" onSubmit={handleSubmit(onSubmit)}>
      <h3>Fill out the form and come to us</h3>
      <input className="careerInput" type="text" placeholder="First and Last name" {...register("name", { required: true })} />
      <div className="doubleInput">
        <input className="careerInput" type="tel" placeholder="Phone number" {...register("phone")} />
        <input className="careerInput" type="email" placeholder="Email" {...register("email", { required: true })} />
      </div>
      <div className="doubleInput">
        <input className="careerInput" type="text" placeholder="Telegram" {...register("telegram")} />
        <input className="careerInput" type="text" placeholder="Link to resume" {...register("link", { required: true })} />
      </div>
      <textarea className="careerInput careerInput--textarea" placeholder="Tell us about yourself" {...register("note")} />

      <div className="submitBox">
        <button type="submit" className="alfa-demo-access" id="FormSubmitBtn">
          Submit
        </button>
      </div>
      {showThx && <ShowThx />}
    </form>
    </>
  )
}