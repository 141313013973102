import React from 'react';
import './lowestBlock.scss';
import BlueBtn from '../../../../components/BlueBtn/BlueBtn';
import useWidth from '../../../../actions/getWidth';

export default function LowestBlock() {
  let width = useWidth();
  let tablet, appimage, centerimage;
  let theme = localStorage.getItem('Theme')
  
  if (width >= 768) {
    if (theme == `"dark"`) {
      tablet = require('./assets/rimage.webp');
      appimage = require('./assets/table-opt.webp');
    }else{
      tablet = require('./assets/rimage light.png');
      appimage = require('./assets/table-opt light.png');
    }
  }
  if (theme == `"dark"`) {
    centerimage = require('./assets/phone3-opt.webp');
  }else{
    centerimage = require('./assets/phone3-opt light.png');
  }
  return (
    <div>
      <div className="lowest_block">
        <div className="content">
          <div className="footer_content content_slim">
            <h2 className="sub_title light-blue-gradient">
              One step to a successful broker launch
            </h2>
            <p className="main_text">
            Apply for getting a turn-key solution through our secure online form. Start your own business within a week
            </p>
            <div className="centered_btn">
              {/* <div className="indent_btn lowest_btn_block">
                <BlueBtn btn_text="Try Demo" link="https://t.me/ScaleTradeBot" />
              </div> */}
            </div>
          </div>
          <div className="footer_img_holder">
            <div className="footer_img_left">
              <img src={tablet} alt={'tablet'} role="presentation" />
            </div>
            <div className="footer_img_center">
              <img src={centerimage} alt={'center'} role="presentation" style={{width: '320px'}} />
            </div>
            <div className="footer_img_right">
              <img src={appimage} alt={'app'} role="presentation" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
