import React, { useState } from 'react';
import './Home.css';
import SliderApp from './components/SliderApp/SliderApp.jsx';
import Link from './components/Link.jsx';
import Monitor from './components/Monitor.jsx';
import { ReactComponent as WindowsIco } from './assets/windowsico.svg';
import { ReactComponent as MacosIco } from './assets/macosico.svg';
import { ReactComponent as LinuxIco } from './assets/linuxico.svg';
import { ReactComponent as BrowserIco } from './assets/browserico.svg';
import { ReactComponent as BotsIco } from './assets/bots.svg';
import { ReactComponent as ChatsIco } from './assets/chats.svg';
import { ReactComponent as LeaderIco } from './assets/leaderboard.svg';
import { ReactComponent as NewsIco } from './assets/news_ico.svg';
import { ReactComponent as PayIco } from './assets/payment.svg';
import { ReactComponent as QuickIco } from './assets/quick-registration.svg';
import { ReactComponent as TourIco } from './assets/tournaments.svg';
import { ReactComponent as UserIco } from './assets/user-account.svg';
import { ReactComponent as DownloadGoogle } from './assets/google-play.svg';
import { ReactComponent as DownloadApple } from './assets/app-store.svg';
import { ReactComponent as DownloadAndroid } from './assets/android-logo.svg';
import { ReactComponent as Drawio } from './assets/drawio.svg';

import { ReactComponent as Apkfile } from '../../assets/apk-file.svg';
import BlueBtn from '../../components/BlueBtn/BlueBtn';
import SEO from '../../components/SEO/SEO';
import imgPlatform from './assets/Trading margin platform.png';
import useWidth from '../../actions/getWidth';
import TwoSides from '../../components/twoSidesBlock/TwoSides';

export default function HomePage() {
  let width = useWidth();
  let tablet, appimage, monitor1, platform, phoneIcon,backoffice, centerimage;
  let theme = localStorage.getItem('Theme')


  if (width >= 768) {
    if (theme == `"dark"`) {
      tablet = require('./assets/table-opt.webp');
      appimage = require('./assets/rimage-1.webp');
      platform = require('./assets/platform.png');
      phoneIcon = require('./assets/phones.png');
      backoffice = require('./assets/backoffice.png');
      centerimage = require('./assets/phone3-opt.webp');
      
    } else {
      tablet = require('./assets/table-opt light.png');
      appimage = require('./assets/rimage-1 light.png');
      platform = require('./assets/platform light.png');
      phoneIcon = require('./assets/phones light.png');
      backoffice = require('./assets/backoffice light.png');
      centerimage = require('./assets/phone3-opt light.png');

    }
  }

  if (theme == `"dark"`) {
    monitor1 = require('./assets/monitors.webp');
  } else {
    monitor1 = require('./assets/monitors light.png');
  }

  let arrLink = [
    {
      link: 'https://api.alfatrading.io:8080/v1/storage/1d7a4d6e-500a-41cb-b95d-b9f12584b690',
      image: <WindowsIco />,
      for: 'Windows',
    },
    {
      link: 'https://api.alfatrading.io:8080/v1/storage/8240f3e0-d17b-4f65-b817-e2b330099feb',
      image: <MacosIco />,
      for: 'MacOS',
    },
    {
      link: 'https://api.alfatrading.io:8080/v1/storage/a8932a93-fc24-4fe4-8086-9f5761084881',
      image: <LinuxIco />,
      for: 'Linux',
    },
  ];
  let apkLink = [
    {
      link: 'https://api.alfatrading.io:8080/v1/storage/41babd1b-48d8-4ea9-9e24-49511761447c',
      image: <Apkfile />,
      for: 'Android',
    },
  ];

  let arrFeatures = [
    {
      ico: <TourIco />,
      title: 'Social Trading',
      main_text: 'Copy trading allows you to copy experienced traders’ strategies in real-time',
    },
    {
      ico: <QuickIco />,
      title: 'Quick registration',
      main_text: 'A simple system for registering your users on the trading platform via email',
    },
    {
      ico: <LeaderIco />,
      title: 'Leaderboard',
      main_text: "Show your traders how well they're doing in comparison to others",
    },
    {
      ico: <UserIco />,
      title: 'User account',
      main_text:
        'Allow users to manage their personal data and customize their trading preferences',
    },
    {
      ico: <ChatsIco />,
      title: 'Economic calendar',
      main_text: 'Life-updated economic calendar with filtering by country',
    },
    {
      ico: <NewsIco />,
      title: 'Market News',
      main_text: 'Your clients will get the most important news from the most reliable sources',
    },
    {
      ico: <PayIco />,
      title: 'Payment page',
      main_text: 'Billing traders is secure and quick, with many payment methods available',
    },
    {
      ico: <BotsIco />,
      title: 'Trading robots',
      main_text: 'Automation of the trading process using advanced trading robots',
    },
  ];

  let arrTwoSides = [

    {
      title: 'Desktop and Mobile Web App',
      main_text: 'A web trading platform branded with your logo and customizable options',
      list: [
        {
          item: 'Expanded statistics of your trading account',
        },
        {
          item: 'Economic calendar and Trading news for experienced traders',
        },
        { item: 'An advanced analytical tools panel' },
      ],
      img: platform,
      side: 'left',
    },

    {
      title: 'Mobile app',
      main_text: 'Your own branded iOS and Android mobile trading apps',
      list: [
        {
          item: 'Exporting trading history',
        },
        {
          item: 'Convenient functional chart',
        },
        { item: 'Selecting the trading assets you need' },
      ],
      img: phoneIcon,
      side: 'right',
    },

    {
      title: 'Backoffice',
      main_text: 'An interface to run your brokerage: manage orders, support trading account, make additional settings',
      list: [
        {
          item: 'Individual dealing settings for client groups and instruments',
        },
        {
          item: 'A real-time exposure monitor displaying life-updated P&L',
        },
        { item: 'Monitoring of orders and positions' },
        { item: "Management of spreads, leverages, and feed quotes" },
      ],
      img: backoffice,
      side: 'left',
    },
  ]
  return (
    <>
      <SEO
        title="ScaleTrade Platform"
        description="Modern and convenient platform for margin trading. One web trading platform for all devices. Easy to use interface. Smart trading tools. Powerful analytics engine."
        name="ScaleTrade"
        type="article"
        link="/"
        img={imgPlatform}
      />
      <div className="alfa_content">
        <div className="alfa_first_block">
          <h1 className="title light-blue-gradient">ScaleTrade Platform</h1>
          <p className="main_text">The platform allows you to provide margin trading</p>
          <p className="main_text">
            The platform is compatible with both the ScaleTrade engine and engines of other
            platforms
          </p>
          <div className="linkHolder">
            <BlueBtn btn_text="Contact Us" click={true} type="subForm"/>
          </div>
          {<Monitor stuleMonitor={{ filter: 'brightness(90%)' }} monitorlink={monitor1} />}
          <div className='two_sides_indent'>
            <div className='two_sides row'>
              <div className='col-lg-6'>
                <h2 className="sub_title sub_title-gradient light-blue-gradient">ST Platform is the complete package</h2>
                <ul className='main_text list_type'>
                  <li>A web and desktop trading platform with your logo and style</li>
                  <li>Your own branded iOS and Android mobile trading apps</li>
                  <li>Everything you need to run your brokerage platform</li>
                  <li>A dedicated account manager with a entire control</li>
                  <li>A dedicated orders control and risk management</li>
                  <li>Free integration with a liquidity provider for your choice</li>
                </ul>
              </div>
              <div className='col-lg-6 img_box'>
                <Drawio className='img_svg' />
              </div>
            </div>
          </div>

        </div>
      </div>

      {/* <div className="alfa_block">
        <div className="alfa_block_content">
          <div className="content_short">
            <h2 className="sub_title light-blue-gradient">
              Availability on any device of your choice
            </h2>
            <p className="main_text">
              Traders can easily swap between devices, trading on any one convenient for them. Our
              trading platform is available in the form of mobile applications on Android, IOS, as
              well as in PWAs format
            </p>
          </div>
          <Monitor monitorlink={phoneIcon} />
        </div>
      </div> */}
      {arrTwoSides.map((i, index) =>
        <TwoSides
          title={i.title}
          main_text={i.main_text}
          list_arr={i.list}
          img={i.img}
          side={i.side}
          link={i.link}
          last={i.last}
          key={index} />
      )}

      <div className="alfa_block">
        <div className="alfa_block_content">
          <div className="content_short">
            <h2 className="sub_title light-blue-gradient">Powerful analytics engine</h2>
            <p className="main_text">
              Analyze market movements using our expanded range of tools. Use indicators and drawing
              tools, change and customize them for yourself.
            </p>
          </div>
          <SliderApp />
        </div>
      </div>
      <div className="alfa_block4 container">
        <div className="platform_centered indent_Title ">
          <h2 className="sub_title light-blue-gradient">Trading terminal features</h2>
          <p className="main_text">
            Make the most of the platform's wide range of features to offer a seamless trading
            experience and become the ideal broker for your traders.
          </p>
        </div>

        <div className="content_features">
          {arrFeatures.map((i, index) => (
            <div className="card_feature" key={index + i.title}>
              {i.ico}
              <h3>{i.title}</h3>
              <p className="why_text">{i.main_text}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="footer_block">
        <div className="content">
          <div className="footer_content content_slim">
            <h2 className="sub_title light-blue-gradient">One platform for all devices</h2>
            <p className="main_text">
              Web desktop, Web mobile, Mac, Win, linux, iOS and Android apps
            </p>
          </div>
          <div className="footer_links">
            <div className="linkHolder hidden-phone">
              {arrLink.map((i, index) => (
                <Link image={i.image} for={i.for} key={index} />
              ))}
            </div>
            <div className="linkHolder">
              <a href="#" rel={'noreferrer'} target="_blank" className="dowload_link">
                <span className="link-img">
                  <BrowserIco />
                </span>
                <span>
                  <span className="link-name">Web Browser</span>
                  <span className="link-subtitle">Desktop & Mobile</span>
                </span>
              </a>
            </div>
            <div className="linkHolder">
              <a href="#" rel={'noreferrer'} target="_blank" className="download_btn dowload_link">
                <span className="link-img">
                  <DownloadGoogle />
                </span>
                <span className="download_text">
                  <span className="link-name">Google Play</span>
                  <span className="link-subtitle">Mobile app</span>
                </span>
              </a>
              <a href="#" rel={'noreferrer'} target="_blank" className="download_btn dowload_link">
                <span className="link-img">
                  <DownloadApple />
                </span>
                <span className="download_text">
                  <span className="link-name">App Store</span>
                  <span className="link-subtitle">Mobile app</span>
                </span>
              </a>
              <a href="#" rel={'noreferrer'} target="_blank" className="download_btn dowload_link">
                <span className="link-img">
                  <DownloadAndroid />
                </span>
                <span className="download_text">
                  <span className="link-name">Android apk</span>
                  <span className="link-subtitle">Mobile app</span>
                </span>
              </a>
            </div>
            {/* <div className="footer_links linkHolder">
              <BlueBtn btn_text="Try Demo" link="https://t.me/ScaleTradeBot" />
            </div> */}
          </div>
          <div className="footer_img_holder">
            <div className="footer_img_left">
              <img src={tablet} alt={'tablet'} role="presentation" />
            </div>
            <div className="footer_img_center">
              <img src={centerimage} alt={'center'} role="presentation" style={{ width: '320px' }} />
            </div>
            <div className="footer_img_right">
              <img src={appimage} alt={'app'} role="presentation" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
