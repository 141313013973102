import React, { useState } from "react";
import "./newForm.scss";
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useForm, Controller } from 'react-hook-form';


export default function NewForm() {
  const [error, setError] = useState('')

function getCookieValue(cookieName) {
    const cookies = document.cookie.split('; ');
    const targetCookie = cookies.find(cookie => cookie.startsWith(`${cookieName}=`));
    return targetCookie ? targetCookie.split('=')[1] : null;
}

const googleCookie = getCookieValue('_ga');
let googleId
if (googleCookie) {
  const parts = googleCookie.split('.');
  if (parts.length > 2) {
    const result = parts.slice(2).join('.');
    googleId = {"clientId": result}
  }
}

  const pasGenerator = () => {
    const lowercaseChars = "abcdefghijklmnopqrstuvwxyz";
    const uppercaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const numbers = "0123456789";
    const symbols = "@$!%*#?&^_-";
    let length = 12

    let allChars = lowercaseChars;
    allChars += numbers;
    allChars += symbols;
    allChars += uppercaseChars;

    let generatedPassword = "";
    generatedPassword += lowercaseChars[Math.floor(Math.random() * lowercaseChars.length)];
    generatedPassword += numbers[Math.floor(Math.random() * numbers.length)];

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * allChars.length);
      generatedPassword += allChars[randomIndex];
    }

    return generatedPassword
  }

  const formSubmit = (data) => {
    axios({
      method: 'post',
      url: 'https://p1.x8y872i.com/endpoint/dev/mt/customer/registration',
      data: {
        email: data.email,
        password: pasGenerator(),
        brand: 'scale',
        prefix: 'sct',
        phone: data.phone,
        name: data.name,
        country: data.country,
        gaData: googleId,
        subfields: {
          type: data.selectInput,
          contact: data.textInput,
          product: data.product,
          messager: data.telegram,
          note: data.comment,
          company: data.company,
        }
      },
    })
      .then(() => {
        // dispatch({ type: 'SELECTFORM', payload: "None" });
        dispatch({ type: 'SHOWTHX', payload: true });
      })
      .catch((error) => {
        setError(error.response)
      });
  };

  const showBanner = useSelector((state) => state.showBanner);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm({
    defaultValues: {
      selectInput: "Email",
      textInput: "",
    },
  });
  console.log(error);
  const selectedValue = watch("selectInput");

  const onSubmit = (data) => {
    console.log(data);
    formSubmit(data);
  };
  console.log(errors);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={`contactForm contactForm--active`}>
      {showBanner && <button
        className="btn-close-white btn-close"
        type="close"
        onClick={(e) => {
          dispatch({ type: 'SHOWBANNER', payload: false });
          dispatch({ type: 'SELECTFORM', payload: 'None' });
          dispatch({ type: 'FORMTYPE', payload: 'None' });

          document.body.classList.remove('modal-open');
        }}
      ></button>}
      <h2 className="sub_title light-blue-gradient">Talk to sales</h2>

      <div className="labelBlock">
        <label>Name*</label>
        <label>Choose how to conatact you*</label>
      </div>
      <div className="inputsBlock">
        <div>
          <input
            type="text"
            placeholder="Name *"
            {...register('name', { required: "The field must not be empty" })}
            className="formInput"
            style={{
              border: errors.name ? "1px solid red" : "",
            }}
          />
          {errors.name && (
            <label style={{ color: "red" }}>{errors.name.message}</label>
          )}
        </div>
        <div>
          <div className="selectedBox">
            <Controller
              name="selectInput"
              control={control}
              render={({ field }) => (
                <select {...field} className="newFormSelect">
                  <option value="Email">Email</option>
                  <option value="Phone">Phone</option>
                  <option value="GoogleMeet">GoogleMeet</option>
                  <option value="Skype">Skype</option>
                  <option value="WhatsApp">WhatsApp</option>
                  <option value="Telegram">Telegram</option>
                </select>
              )}
            />
            <Controller
              name="textInput"
              control={control}
              render={({ field }) => (
                <input
                  className="formInput"
                  {...field}
                  type="text"
                  placeholder={selectedValue ? `Contact me using ${selectedValue}` : ""}
                  {...register("textInput", { required: "The field must not be empty" })}
                  style={{
                    border: errors.textInput ? "1px solid red" : "",
                  }}
                />
              )}
            />
          </div>
          {errors.textInput && (
            <label style={{ color: "red" }}>{errors.textInput.message}</label>
          )}
        </div>

      </div>
      <div className="labelBlock">
        <label>Company*</label>
      </div>
      <div className="inputsBlock">
        <div>
          <div className="newForm_inputs">
            <input
              type="text"
              placeholder="Company *"
              {...register('company', { required: "The field must not be empty" })}
              className="formInput"
              style={{
                border: errors.company ? "1px solid red" : "",
              }}
            />
            {errors.company && (
            <label style={{ color: "red" }}>{errors.company.message}</label>
            )}
            <div className="labelBlock">
              <label>Email*</label>
            </div>
            <input
              type="email"
              placeholder="Contact Email *"
              {...register('email', { required: "The field must not be empty" })}
              className="formInput"
              style={{
                border: errors.email ? "1px solid red" : "",
              }}
            />
          </div>
          {error.status == 409 && <div>
            <label style={{ color: "red" }}>Email already exist*</label>
          </div>}
          {errors.email && (
            <label style={{ color: "red" }}>{errors.email.message}</label>
            )}

        </div>
        <div className="textareaBlock">
        <textarea
          type="text"
          placeholder="Message*"
          {...register('comment', { required: "The field must not be empty" })}
          className="formInput detailes textBoxInput"
          style={{
            border: errors.comment ? "1px solid red" : "",
          }}
        />
          {errors.comment && (
            <label style={{ color: "red" }}>{errors.comment.message}</label>
            )}
        </div>
      </div>
      <div className="labelBlock">
        <label>Please complete these required fields</label>
      </div>
      <div className={`${errors.agree && 'error'} checkBlock`}>
        <input
          type="checkbox"
          id="checkAgree"
          className="cheackAgree"
          placeholder="Agree"
          {...register('agree', { required: true })}
        />
        <label for="checkAgree">
          <p>I understand and agree that my personal data will be collected and processed according to the <a href="https://scaletrade.com/privacy-policy"> Privacy policy</a></p>
        </label>
      </div>
      <div className={`${errors.information && 'error'} checkBlock`}>
        <input
          type="checkbox"
          id="checkInfo"
          placeholder="Information"
          {...register('information', { required: true })}
        />
        <label for="checkInfo">
          Please send me information about new product releases, price changes, and special offers
          from ScaleTrade. I am aware that my consent could be revoked at any time by clicking the
          unsubscribe link inside any email received from ScaleTrade.
        </label>
      </div>
      <div className="submitBox">
        <button type="submit" className="submit" id="FormSubmitBtn">
          Submit
        </button>
      </div>
    </form>
  );
}