import React, {useEffect, useRef, useState} from 'react';
import useWidthHook from '../../Hooks/WidthState.jsx';
import {ReactComponent as WhiteLogo} from '../../../assets/whitelogo.svg';
import {ReactComponent as BlackLogo} from '../../../assets/blacklogo.svg';
import {ReactComponent as Hamburher} from '../assets/hamburger.svg';
import {useDispatch, useSelector} from 'react-redux';
import '../Header.scss';

const Accordion = (props) => {
  const [state, setState] = useState(false);
  const [heightElement, setheightElement] = useState(0);
  const accordionRef = useRef();

  useEffect(() => {
    setheightElement(accordionRef.current.children[0].offsetHeight);
  }, [setheightElement, state]);

  return (
    <div className={`accordion__row${state ? ' open' : ''}`}>
      <div className="accordion__btn" onClick={() => setState(!state)}>
        <h3>{props.title}</h3>
        <p className="open_cross"></p>
      </div>
      <div
        className="accordion__collapse"
        ref={accordionRef}
        style={state ? {height: heightElement * props.array.length + 'px'} : {height: 0}}
      >
        {props.array.map((i, index) => (
          <a className="dropdown-item dropdown-item-centered" href={i.href} key={index + i.item}>
            <div className="dropdown-text">
              <p>{i.item}</p>
              <p className="subText">{i.subText && i.subText}</p>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

function HeaderLinks() {
  const a = useSelector((state) => state.langTokens);
  let Width = useWidthHook();

  const test = [
    {
      title: 'Products',
      arr: [
        {href: '/platform', item: 'ST Platform', img: '', subText: 'Trading Platform'},
        {href: '/crm', item: 'ST CRM', img: '', subText: 'Client relationship management'},
        {href: '/feeder', item: 'ST Feeder', img: '', subText: 'Quotes feeder'},
      ],
    },
    {
      title: 'Modules',
      arr: [
        {
          href: '/copy-trading',
          item: 'Copy Trading',
          img: '',
          subText: 'Social trading platform',
        },
        {
          href: '/prop-trading',
          item: 'Prop Trading',
          img: '',
          subText: 'Advanced trading challenges',
        },
        {href: '#', item: 'Trading robots', img: '', subText: 'Coming soon'},
      ],
    },
    // {
    //   title: "Services",
    //   arr: [
    //    { href: "#", item: "Custom website development", img: "", subText: "Coming soon"},
    //    { href: "#", item: "Custom mobile app development", img: "", subText: "Coming soon"},
    //    { href: "#", item: "Custom integration (FIX Protocol, MetaQuotes API)", img: "", subText: "Coming soon"},
    //    { href: "#", item: "Custom Platform Development", img: "", subText: "Coming soon"},
    //    { href: "#", item: "Administrative trading infrastructions", img: "", subText: "Coming soon"},
    //    { href: "#", item: "ST Platform MT4 Platform MT5 Platform", img: "", subText: "Coming soon"},

    //   ]
    // },
    {
      title: 'Company',
      arr: [
        {href: '/about', item: 'About us', img: '', subText: 'Our company life'},
        {
          href: 'https://blog.scaletrade.com/',
          item: 'Blog',
          img: '',
          subText: 'The latest news',
        },
        {
          href: '/technology-stack',
          item: 'Technology stack',
          img: '',
          subText: 'Coming soon',
        },
        {
          href: '/career',
          item: 'Сareer',
          img: '',
          subText: 'Job vacancies',
        },
      ],
    },
  ];

  return (
    <>
      {test.map((i, index) => (
        <li className="dropdown" key={index}>
          <button
            type="button"
            className="dropdown-toggle alfa-header-dropdown alfa-footer-dropdown"
            data-bs-
            aria-expanded="false"
            style={{marginLeft: '0', height: 'fit-content', alignSelf: 'center'}}
          >
            {i.title}
          </button>
          <ul className="dropdown-menu">
            {i.arr.map((i, index) => (
              <li
                className={`icon_text ${i.href === '#' && 'icon_text-disabled'}`}
                key={index + i.item}
              >
                <a className="dropdown-item dropdown-item-centered" href={i.href !== '#' && i.href}>
                  <div className="dropdown-box">
                    <div>{i.img && i.img}</div>
                    <div className="dropdown-text">
                      <p>{i.item}</p>
                      <p className="subText">{i.subText && i.subText}</p>
                    </div>
                  </div>
                </a>
              </li>
            ))}
          </ul>
        </li>
      ))}
      <li className="dropdown">
        <a className="dropdown-item dropdown-item-centered prices-btn" href="/prices">
          Prices
        </a>
      </li>
      {Width < 650 ? (
        <>
          <li className="nav-item">
            <a className="nav-link" href="http://req.alfatrading.io/">
              Get Demo Access
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#" download>
              {a.PRESENTATION_TEXT} {a.PRESENTATION_TEXT1}
            </a>
          </li>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default function MiddleContent() {
  let Width = useWidthHook();

  const [theme, setTheme] = useState(() => {
    const mode = JSON.parse(localStorage.getItem('Theme'));
    return mode || 'light';
  });

  let dispatch = useDispatch();
  const arrLink = [
    {
      title: 'Products',
      arr: [
        {href: '/platform', item: 'ST Platform', img: '', subText: 'Trading Platform'},
        {href: '/crm', item: 'ST CRM', img: '', subText: 'Client relationship management'},
        {href: '/feeder', item: 'ST Feeder', img: '', subText: 'Quotes feeder'},
      ],
    },
    {
      title: 'Modules',
      arr: [
        {
          href: '/copy-trading',
          item: 'Copy Trading',
          img: '',
          subText: 'Social trading platform',
        },
        {href: '#', item: 'Prop Trading', img: '', subText: 'Coming soon'},
        {href: '#', item: 'Trading robots', img: '', subText: 'Coming soon'},
      ],
    },
    // {
    //   title: "Services",
    //   arr: [
    //    { href: "#", item: "Custom website development", img: "", subText: "Coming soon"},
    //    { href: "#", item: "Custom mobile app development", img: "", subText: "Coming soon"},
    //    { href: "#", item: "Custom integration (FIX Protocol, MetaQuotes API)", img: "", subText: "Coming soon"},
    //    { href: "#", item: "Custom Platform Development", img: "", subText: "Coming soon"},
    //    { href: "#", item: "Administrative trading infrastructions", img: "", subText: "Coming soon"},
    //    { href: "#", item: "ST Platform MT4 Platform MT5 Platform", img: "", subText: "Coming soon"},

    //   ]
    // },
    {
      title: 'Company',
      arr: [
        {href: '/about', item: 'About us', img: '', subText: 'Our company life'},
        {
          href: 'https://blog.scaletrade.com/',
          item: 'Blog',
          img: '',
          subText: 'The latest news',
        },
        {
          href: '/technology-stack',
          item: 'Technology stack',
          img: '',
          subText: 'Coming soon',
        },
        {
          href: '/career',
          item: 'Сareer',
          img: '',
          subText: 'Job vacancies',
        },
      ],
    },
  ];
  return (
    <>
      {Width < 1095 ? (
        <>
          <button
            className="alfa-header__hamburger-menu"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasWithBackdrop"
            aria-controls="offcanvasWithBackdrop"
          >
            {<Hamburher />}
          </button>
          <div
            className="offcanvas offcanvas-end"
            tabIndex="-1"
            id="offcanvasWithBackdrop"
            aria-labelledby="offcanvasWithBackdropLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasWithBackdropLabel">
                <a className="navbar-brand" href="/" style={{width: '50px'}}>
                  {theme === 'light' ? (
                    <BlackLogo style={{width: '200px', height: '28px'}} />
                  ) : (
                    <WhiteLogo style={{width: '200px', height: '28px'}} />
                  )}
                </a>
              </h5>
              <button
                type="button"
                className="btn-close-white btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              {arrLink.map((i, index) => (
                <Accordion array={i.arr} title={i.title} key={index + i.title} />
              ))}
            </div>
            <div className="offcanvas-footer">
              <div className="btns_Box">
                <a>
                  <button
                    type="button"
                    className="alfa-presentation"
                    onClick={() => {
                      dispatch({type: 'SHOWBANNER', payload: true});
                      dispatch({type: 'FORMTYPE', payload: 'subForm'});
                    }}
                  >
                    <div className="flex-column">
                      <span className="alfa-span-btn alfa-span-btn-color">Turn-key broker </span>
                      <small className="alfa-small"></small>
                    </div>
                  </button>
                </a>
                <a>
                  <button
                    type="button"
                    className="alfa-demo-access"
                    onClick={() => {
                      dispatch({type: 'SHOWBANNER', payload: true});
                      dispatch({type: 'FORMTYPE', payload: 'subForm'});
                    }}
                  >
                    <div className="flex-column">
                      <span className="alfa-span-btn">Contact Us </span>
                    </div>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="header_middle_content">
          <HeaderLinks />
        </div>
      )}
    </>
  );
}
