import React, { useEffect, useRef, useState } from 'react';
import './banner.css';
import Form from './components/form/Form';
import { useDispatch, useSelector } from 'react-redux';
import ShowThx from './components/showThx/showThx';
import { ReactComponent as Arrow } from './assets/arrow.svg';
import { ReactComponent as Tower } from './assets/tower.svg';
import { ReactComponent as User } from './assets/user.svg';
import NewForm from './components/newForm/newForm';


export default function Banner() {
  const dispatch = useDispatch();
  const main = useRef(null);
  const showThx = useSelector((state) => state.showThx);
  const selectForm = useSelector((state) => state.selectForm);
  const formType = useSelector((state) => state.formType);
  const [form, setForm] = useState(false)
  console.log(formType);

  useEffect(() => {
    const onClick = (e) => {
      if (main.current && main.current.contains(e.target)) {
          return;
      }
      dispatch({ type: 'SHOWTHX', payload: false });
      dispatch({ type: 'SHOWBANNER', payload: false });
      dispatch({ type: 'SELECTFORM', payload: "None" });
      dispatch({ type: 'FORMTYPE', payload: 'None' });
  };
    document.body.classList.add('modal-open');

    const onKeydown = ({ key }, KeyboardEvent) => {
      console.log('Key off');
      switch (key) {
        case 'Escape':
          dispatch({ type: 'SHOWTHX', payload: false });
          dispatch({ type: 'SHOWBANNER', payload: false });
          dispatch({ type: 'SELECTFORM', payload: "None" });
          dispatch({ type: 'FORMTYPE', payload: 'None' });
          
          break;
      }
    };
    document.addEventListener('click', onClick);
    document.addEventListener('keydown', onKeydown);
    return () => {
      document.removeEventListener('click', onClick);
      document.removeEventListener('keydown', onKeydown);
      document.body.classList.remove('modal-open');
    };
  }, []);

  return (
    <>
      <div className="bannerBox">
        <div className={`banner ${showThx && 'bannerThx'}`} ref={main}>
          {showThx !== true && formType == "Main" && <div className={`selectBanner ${selectForm === "None" ? "selectBanner--active" : ""}`}>
            <div className='selectBanner__TitleBox'>
              <p className='main_text'>For better user experience and content</p>
              <h1 className='light-blue-gradient'>Tell us who you are?</h1>
            </div>
            <div className='selectBanner__MainBox'>
              <div className='select'>
                <div className='svg_box'>
                  <Tower />
                </div>
                <h3 className='light-blue-gradient'>
                  I'm a Broker
                </h3>
                <p className='main_text'>
                  I need a Trading Platform for my Forex Broker business or Prop trading company.
                </p>
                <button className='btn_demo' onClick={(e) => { dispatch({ type: 'SELECTFORM', payload: "Broker" }) }}>
                  Continue <Arrow className="btn_arrow" />
                </button>
              </div>
              <div class="or">
                <span>OR</span>
              </div>
              <div className='select'>
                <div className='svg_box'>
                  <User />
                </div>
                <h3 className='light-blue-gradient'>
                  I'm a Trader
                </h3>
                <p className='main_text'>
                  I'm interested in trading forex instruments or buying a prop challenge.
                </p>
                <button className='btn_demo' onClick={(e) => { dispatch({ type: 'SELECTFORM', payload: "Client" }) }}>
                  Continue <Arrow className="btn_arrow" />
                </button>
              </div>
            </div>
          </div>}
          {formType == "Main" && <Form />}
          {formType == "subForm" && <NewForm />}
          {showThx && <ShowThx />}
        </div>
      </div>
    </>
  );
}
