import React from 'react';
import NavHead from '../Header/Header.jsx';
import Footer from '../Footer/Footer.jsx';
import AboutPage from '../../Pages/About/AboutPage.jsx';
import HomePage from '../../Pages/HomePage/HomeContent';
import {Routes, Route} from 'react-router-dom';
import NewHomePage from '../../Pages/NewHomePage/newHomePage.jsx';
import CrmPage from '../../Pages/CrmPage/crmPage.jsx';
import PricesPage from '../../Pages/PricesPage/pricesPage.jsx';
import FiderPage from '../../Pages/FiderPage/FiderPage.jsx';
import PrivacyPage from '../../Pages/PrivacyPage/privacyPage.jsx';
import CopyTrading from '../../Pages/CopyTradingPage/CopyTrading.jsx';
import XproBanner from '../XproBanner/XproBanner.jsx';
import TechnologyStack from '../../Pages/TechnologyStack/TechnologyStack.jsx';
import NotFoundPage from '../../Pages/NotFoundPage/NotFoundPage.jsx';
import PropsTrading from '../../Pages/PropsPage/PropTrading.jsx';
import './check.css';
import VacanciesPage from '../../Pages/Vacancies/vacancies.jsx';
import CookieBanner from '../CookieBanner/CookieBanner.jsx';

export default function CheckCollapse() {
  return (
    <>
      <XproBanner />
      <NavHead />
      <Routes>
        <Route path="/" element={<NewHomePage />} />
        <Route path="platform" element={<HomePage />} />
        <Route path="crm" element={<CrmPage />} />
        <Route path="copy-trading" element={<CopyTrading />} />
        <Route path="feeder" element={<FiderPage />} />
        <Route path="about" element={<AboutPage />} />
        <Route path="prices" element={<PricesPage />} />
        <Route path="privacy-policy" element={<PrivacyPage />} />
        <Route path="prop-trading" element={<PropsTrading />} />
        <Route path="technology-stack" element={<TechnologyStack />} />
        <Route path="career" element={<VacanciesPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <CookieBanner />
      <Footer />
    </>
  );
}
