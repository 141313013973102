/* global gtag */
import React, { useState, useEffect } from 'react';
import './CookieBanner.scss'

const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [consent, setConsent] = useState({
    necessary: true,
    analytics: true,
    preferences: true,
    marketing: false,
  });

  useEffect(() => {
    const storedConsent = localStorage.getItem('consentMode');
    if (!storedConsent) {
      setIsVisible(true);
    }
  }, []);

  const handleAcceptAll = () => {
    updateConsent({
      necessary: true,
      analytics: true,
      preferences: true,
      marketing: true,
    });
    setIsVisible(false);
  };

  const handleAcceptSome = () => {
    updateConsent(consent);
    setIsVisible(false);
  };

  const handleRejectAll = () => {
    updateConsent({
      necessary: false,
      analytics: false,
      preferences: false,
      marketing: false,
    });
    setIsVisible(false);
  };

  const updateConsent = (updatedConsent) => {
    const consentMode = {
      functionality_storage: updatedConsent.necessary ? 'granted' : 'denied',
      security_storage: updatedConsent.necessary ? 'granted' : 'denied',
      ad_storage: updatedConsent.marketing ? 'granted' : 'denied',
      analytics_storage: updatedConsent.analytics ? 'granted' : 'denied',
      personalization_storage: updatedConsent.preferences ? 'granted' : 'denied',
    };
    if (typeof (gtag) === 'function') {
      gtag('consent', 'update', consentMode);
    } else {
      console.warn('gtag is not defined.');
    }
    localStorage.setItem('consentMode', JSON.stringify(consentMode));
  };

  if (!isVisible) return null;

  return (
    <div id="cookie-consent-banner" className="cookie-consent-banner fixed-bottom">
      <div className='container cookie-banner-info'>
        <h2 className='light-blue-gradient'>Cookie settings</h2>
        <p className='main_text'>
          We use cookies to provide you with the best possible experience. They also
          allow us to analyze user behavior in order to constantly improve the website
          for you.
        </p>
        <div className='cookie-consent-buttonBox'>
          <button onClick={handleAcceptAll} className="cookie-consent-button">
            Accept All
          </button>
          <button onClick={handleAcceptSome} className="cookie-consent-button">
            Accept Selection
          </button>
          <button onClick={handleRejectAll} className="cookie-consent-button">
            Reject All
          </button>
        </div>
        <div className="cookie-consent-options">
          <label className='main_text'>
            <input
              type="checkbox"
              checked
              disabled
            />
            Necessary
          </label>
          <label className='main_text'>
            <input
              type="checkbox"
              checked={consent.analytics}
              onChange={(e) =>
                setConsent({ ...consent, analytics: e.target.checked })
              }
            />
            Analytics
          </label>
          <label className='main_text'>
            <input
              type="checkbox"
              checked={consent.preferences}
              onChange={(e) =>
                setConsent({ ...consent, preferences: e.target.checked })
              }
            />
            Preferences
          </label>
          <label className='main_text'>
            <input
              type="checkbox"
              checked={consent.marketing}
              onChange={(e) =>
                setConsent({ ...consent, marketing: e.target.checked })
              }
            />
            Marketing
          </label>
        </div>
      </div>
    </div>
  );
};

export default CookieBanner;